<template>
  <v-container class="my-10 mb-8 pb-8 dcl-form">
    <v-dialog v-model="submitDialog" max-width="500px">
      <v-card>
        <v-card-title>{{ resMessage.title || 'Your form has been submitted' }}</v-card-title>
        <v-card-text>
          <p>{{ resMessage.message || 'You will get a copy of your form in the email you provided' }}</p>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="submitLoader" max-width="500px">
      <v-card>
        <v-card-title>Your form is being submitted</v-card-title>
        <v-card-text>
          <p>Your form is being processed. This might take some time...</p>
        </v-card-text>
      </v-card>
    </v-dialog>


    <v-snackbar v-model="snackBar.show" :timeout="3000" :color="snackBar.color" elevation="24" location="bottom right">
      <span class="snackbar__message">{{ snackBar.text }}</span>
    </v-snackbar>

    <div class="dcl__header">
      <h2><v-btn class="me-2" icon href="javascript:history.back()"><v-icon>mdi-chevron-left</v-icon></v-btn> Securities
        Transfer Form</h2>

      <v-divider class="my-8"> </v-divider>
    </div>

    <div class="form-container">
      <v-form v-model="valid" ref="STForm">
        <v-container>
          <!-- ? vor BOID -->
          <v-row dense justify="center">
            <v-col cols="12" sm="10" md="10">
              <section class="pb-2">
                <div class="mb-2">
                  <span class="grey--text text--darken-3 inputLabel">
                    From BOID
                  </span>
                </div>

                <v-text-field variant="outlined" placeholder="e.g. 1XXXXXXXX" density="compact"
                  v-model="clientInfo.fromBOID" :rules="stringRules"></v-text-field>
              </section>
            </v-col>
          </v-row>
          <!-- ? nach BOID -->
          <v-row dense justify="center">
            <v-col cols="12" sm="10" md="10">
              <section class="pb-2">
                <div class="mb-2">
                  <span class="grey--text text--darken-3 inputLabel">
                    To BOID
                  </span>
                </div>

                <v-text-field variant="outlined" placeholder="e.g. 1XXXXXXXX" density="compact"
                  v-model="clientInfo.toBOID" :rules="stringRules"></v-text-field>
              </section>
            </v-col>
          </v-row>
          <!-- ? Citizenship Issue District -->
          <v-row dense justify="center">
            <v-col cols="12" sm="10" md="10">
              <section class="pb-2">
                <div class="mb-2">
                  <span class="grey--text text--darken-3 inputLabel">
                    Citizenship Issue District
                  </span>
                </div>

                <v-text-field variant="outlined" placeholder="e.g. Morang" density="compact"
                  v-model="clientInfo.citizenshipIssueDistrict" :rules="stringRules"></v-text-field>
              </section>
            </v-col>
          </v-row>
          <!-- ? Citizenship Number -->
          <v-row dense justify="center">
            <v-col cols="12" sm="10" md="10">
              <section class="pb-2">
                <div class="mb-2">
                  <span class="grey--text text--darken-3 inputLabel">
                    Citizenship Number
                  </span>
                </div>

                <v-text-field variant="outlined" placeholder="e.g. XX-XX-XX-XXXXXX" density="compact"
                  v-model="clientInfo.citizenshipNumber" :rules="stringRules"></v-text-field>
              </section>
            </v-col>
          </v-row>
          <!-- ? Citizenship Issue Year -->
          <v-row dense justify="center">
            <v-col cols="12" sm="10" md="10">
              <section class="pb-2">
                <div class="mb-2">
                  <span class="grey--text text--darken-3 inputLabel">
                    Citizenship Issue Year
                  </span>
                </div>

                <v-text-field variant="outlined" placeholder="e.g. 2000" density="compact" v-model="clientInfo.issueYear"
                  :rules="stringRules"></v-text-field>
              </section>
            </v-col>
          </v-row>
          <!-- ? Name -->
          <v-row dense justify="center">
            <v-col cols="12" sm="10" md="10">
              <section class="pb-2">
                <div class="mb-2">
                  <span class="grey--text text--darken-3 inputLabel">
                    Client's Name
                  </span>
                </div>

                <v-text-field variant="outlined" placeholder="e.g. Joseph Doe" density="compact" v-model="clientInfo.name"
                  :rules="stringRules"></v-text-field>
              </section>
            </v-col>
          </v-row>
          <!-- ? E-Mail Addresse -->
          <v-row dense justify="center">
            <v-col cols="12" sm="10" md="10">
              <section class="pb-2">
                <div class="mb-2">
                  <span class="grey--text text--darken-3 inputLabel">
                    Client's Email (You will receive a copy of the form here)
                  </span>
                </div>

                <v-text-field variant="outlined" placeholder="e.g. joe@email.com" density="compact"
                  v-model="clientInfo.email" :rules="stringRules"></v-text-field>
              </section>
            </v-col>
          </v-row>
          <!-- ? Name des Vater -->
          <v-row dense justify="center">
            <v-col cols="12" sm="10" md="10">
              <section class="pb-2">
                <div class="mb-2">
                  <span class="grey--text text--darken-3 inputLabel">
                    Father's Name
                  </span>
                </div>

                <v-text-field variant="outlined" placeholder="e.g. Jonathan Doe" density="compact"
                  v-model="clientInfo.fatherName" :rules="stringRules"></v-text-field>
              </section>
            </v-col>
          </v-row>
          <!-- ? Name der Mutter -->
          <v-row dense justify="center">
            <v-col cols="12" sm="10" md="10">
              <section class="pb-2">
                <div class="mb-2">
                  <span class="grey--text text--darken-3 inputLabel">
                    Mother's Name
                  </span>
                </div>

                <v-text-field variant="outlined" placeholder="e.g. Jane Doe" density="compact"
                  v-model="clientInfo.motherName" :rules="stringRules"></v-text-field>
              </section>
            </v-col>
          </v-row>
          <!-- ? Name des Ehepartners -->
          <v-row dense justify="center">
            <v-col cols="12" sm="10" md="10">
              <section class="pb-2">
                <div class="mb-2">
                  <span class="grey--text text--darken-3 inputLabel">
                    Spouse's Name
                  </span>
                </div>

                <v-text-field variant="outlined" placeholder="e.g. Jane Doe" density="compact"
                  v-model="clientInfo.spouseName"></v-text-field>
              </section>
            </v-col>
          </v-row>
          <!-- ? Name des Groß Vater -->
          <v-row dense justify="center">
            <v-col cols="12" sm="10" md="10">
              <section class="pb-2">
                <div class="mb-2">
                  <span class="grey--text text--darken-3 inputLabel">
                    Grand Father's Name
                  </span>
                </div>

                <v-text-field variant="outlined" placeholder="e.g. George Doe" density="compact"
                  v-model="clientInfo.grandfatherName" :rules="stringRules"></v-text-field>
              </section>
            </v-col>
          </v-row>

          <!-- ? Grund für die Versetzung -->
          <v-row dense justify="center">
            <v-col cols="12" sm="10" md="10">
              <section class="pb-2">
                <div class="mb-2">
                  <span class="grey--text text--darken-3 inputLabel">
                    Reason for Transfer
                  </span>
                </div>

                <v-text-field variant="outlined" placeholder="e.g. Gift" density="compact"
                  v-model="clientInfo.reasonForTransfer" :rules="stringRules"></v-text-field>
              </section>
            </v-col>
          </v-row>
          <!-- ? Geburtsdatum -->
          <v-row dense justify="center">
            <v-col cols="12" sm="10" md="10">
              <section class="pb-2">
                <div class="mb-2">
                  <span class="grey--text text--darken-3 inputLabel">
                    Date of Birth
                  </span>
                </div>

                <v-text-field variant="outlined" placeholder="e.g. 2000/01/01 " density="compact"
                  v-model="clientInfo.dateOfBirth" :rules="stringRules"></v-text-field>
              </section>
            </v-col>
          </v-row>

          <!-- ? Aktien -->
          <v-row dense justify="center">
            <v-col cols="12" md="10">
              <span class="text--darken-3 inputLabel">Securities</span>
            </v-col>
            <v-col cols="12" md="10">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        Script Name
                      </th>
                      <th class="text-left">
                        Script Quantity
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="sec, index in clientInfo.securities" :key="index">
                      <td>{{ sec.scriptName }}</td>
                      <td>{{ sec.scriptQuantity }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <v-row class="mt-4">
                <v-col cols="12" sm="12" md="6">
                  <v-text-field variant="outlined" label="Script Name" density="compact"
                    v-model="security.scriptName"></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field variant="outlined" label="Script Quantity" density="compact"
                    v-model="security.scriptQuantity"></v-text-field>
                </v-col>
              </v-row>
              <v-btn style="width: 100%; text-transform: capitalize; letter-spacing: 0px;" elevation="0" color="success"
                @click="addSecurity"><v-icon left>mdi-plus</v-icon> Add</v-btn>
            </v-col>
          </v-row>

          <!-- ? Client Signature -->
          <v-row dense justify="center">
            <v-col cols="12" md="10">
              <span class="text--darken-3 inputLabel">Signature</span>
            </v-col>
            <v-col cols="12" md="10">
              <v-file-input accept="image/*" placeholder="Upload Signature in JPG or PNG format" flat density="compact"
                append-icon="mdi-paperclip" prepend-icon="" class="text--blue" dense v-model="clientInfo.signature"
                :rules="stringSizeRules"></v-file-input>
            </v-col>
          </v-row>
          <!-- ? Submit -->
          <v-row dense justify="center">
            <v-col cols="12" sm="10" md="10">
              <section class="pb-2">
                <v-btn elevation="0" color="success" style="text-transform: capitalize; letter-spacing: 0px;"
                  @click="submit">Submit
                  <v-icon right>mdi-content-save</v-icon></v-btn>
              </section>
            </v-col>
          </v-row>

        </v-container>

      </v-form>
    </div>
  </v-container>
</template>

<script>
import STFService from "../services/API/STFService"
export default {
  name: "STF",
  data() {
    return {
      valid: false,
      submitDialog: false,
      submitLoader: false,
      snackBar: {
        show: false,
        color: '',
        text: ''
      },
      resMessage: {
        title: null,
        message: null
      },
      security: {
        scriptName: '',
        scriptQuantity: '',
      },
      clientInfo: {
        fromBOID: '',
        toBOID: '',
        citizenshipIssueDistrict: '',
        citizenshipNumber: '',
        issueYear: '',
        name: '',
        fatherName: '',
        motherName: '',
        spouseName: '',
        grandfatherName: '',
        reasonForTransfer: '',
        securities: [],
        signature: '',
        dateOfBirth: '',
        email: ''
      },
      stringRules: [
        v => !!v || "This field is required",
      ],
      stringSizeRule: [
        v => !!v && v.size < 1000000 || 'File is required and the file size should not exceed 1MB!'
      ],
      sizeRule: [
        v => (v ? v.size < 1000000 : true) || 'File size should not exceed 1MB!'
      ]
    }
  },
  methods: {
    addSecurity() {
      if (this.security.scriptName && this.security.scriptQuantity) {
        this.clientInfo.securities.push({
          scriptName: this.security.scriptName,
          scriptQuantity: this.security.scriptQuantity
        });
        this.security.scriptName = ''
        this.security.scriptQuantity = ''
      }
    },
    async submit() {
      if (this.$refs.STForm.validate() && this.clientInfo.securities.length > 0) {
        this.submitLoader = true;
        await STFService.FillSTFForm(this.clientInfo).then((response) => {
          if (response.status != 200) {
            this.resMessage.title = "Could Not Submit Form"
            this.resMessage.message = "There was an error while submitting this form"
          }

          this.submitLoader = false;
          this.submitDialog = true;
        })
      } else {
        this.snackBar.show = true;
        this.snackBar.color = 'red';
        this.snackBar.text = 'Fill all the fields and add atleast one security info for transfer.'
      }

    }
  }
}
</script>

<style scoped>
.form-container {
  max-width: 800px;
  margin-inline: auto;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 0.4rem;
  padding-block: 2rem;
}

.inputLabel {
  font-weight: 600 !important;
  font-size: 0.9rem;
}

.inputTitle {
  font-weight: 600 !important;
  font-size: 1.2rem;
}

.dcl__header {
  max-width: 800px;
  margin-inline: auto;
}
</style>
