import APIManager from './APIManager';

// ? Minor to Individual Account Transfer
const FillSTFForm = async (queryObj) => {
    const formData = new FormData();

    formData.append('fromBOID', queryObj.fromBOID);
    formData.append('toBOID', queryObj.toBOID);
    formData.append(
        'citizenshipIssueDistrict',
        queryObj.citizenshipIssueDistrict,
    );
    formData.append('citizenshipNumber', queryObj.citizenshipNumber);
    formData.append('issueYear', queryObj.issueYear);
    formData.append('name', queryObj.name);
    formData.append('fatherName', queryObj.fatherName);
    formData.append('motherName', queryObj.motherName);
    formData.append('spouseName', queryObj.spouseName);
    formData.append('grandfatherName', queryObj.grandfatherName);
    formData.append('reasonForTransfer', queryObj.reasonForTransfer);
    formData.append('securities', JSON.stringify(queryObj.securities));
    formData.append('signature', queryObj.signature);
    formData.append('dateOfBirth', queryObj.dateOfBirth);
    formData.append('email', queryObj.email);
    const formResponse = APIManager.axios
        .post(`/api/v1/public/securities-transfer`, formData)
        .then((response) => {
            return response;
        });

    return formResponse;
};

export default {
    FillSTFForm,
};
